import React, { useContext } from "react"

import { AIToolsContext } from "../../context"
import BasicOutput from "./BasicOutput"
import ProOutput from "./ProOutput"

const LessonPlanOutput = ({
  autoGenerateHandout,
  autoGenerateNarration,
  resetState,
}: {
  autoGenerateNarration?: boolean
  autoGenerateHandout?: boolean
  resetState: () => void
}) => {
  const { overviewData } = useContext(AIToolsContext)

  if (overviewData?.isPro) {
    return <ProOutput />
  }

  return (
    <div>
      <BasicOutput
        autoGenerateHandout={autoGenerateHandout}
        autoGenerateNarration={autoGenerateNarration}
        resetState={resetState}
      />
    </div>
  )
}

export default LessonPlanOutput
