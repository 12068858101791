import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  Button,
} from "@suraasa/placebo-ui-legacy"
import { HandoutResponse, ToolType } from "api/resources/aiTools/types"
import { Download, NavArrowDown } from "iconoir-react"
import { useContext } from "react"
import { capitalizeFirstLetter } from "utils/helpers"

import Paper from "../../../../Paper"
import { AIToolsContext } from "../../../context"
import { ExportOutputButton } from "../../Export/ContentActions"
import MarkdownRenderer from "../../MarkdownRenderer"
import RegenerateOutput, { RegenerateOutputProps } from "../../RegenerateOutput"

const HandoutContent = ({
  output,
  title: customTitle,
  defaultOpen = false,
  showDownloadButton = false,
  regenerate,
  ...props
}: HandoutResponse &
  RegenerateOutputProps & {
    defaultOpen?: boolean
    title?: string
    showDownloadButton?: boolean
    regenerate?: {
      regenerateResponse?: (reason: string) => void
      isLoading?: boolean
      isError?: boolean
      isSuccess?: boolean
    }
  }) => {
  const { overviewData, currentResponseId: contextCurrentResponseId } =
    useContext(AIToolsContext)

  const currentResponseId = (props.currentResponseId ||
    contextCurrentResponseId)!
  const title = (customTitle || overviewData?.title || "").replaceAll("*", "")

  return (
    <div className="mb-4 flex flex-col gap-2">
      <Paper
        className="pt-0"
        endSlot={
          <RegenerateOutput
            copyText={output}
            responseIds={props.responseIds}
            currentResponseId={props.currentResponseId}
            isPositiveResponse={props.isPositiveResponse}
            onResponseIdChange={props.onResponseIdChange}
            type={props.type}
            refetchOverviewData={props.refetchOverviewData}
            regenerate={regenerate}
          />
        }
      >
        <Accordion expanded={defaultOpen}>
          <AccordionHeader
            icon={<NavArrowDown color="black" />}
            className="!pt-3 text-start [&>.AccordionHeader-content]:w-full"
          >
            <div className="flex items-center justify-between gap-1">
              <Typography variant="title2" className="grow">
                {capitalizeFirstLetter(title)}
              </Typography>
            </div>
          </AccordionHeader>

          <AccordionDetails>
            {showDownloadButton && (
              <ExportOutputButton
                currentResponseId={currentResponseId}
                toolType={ToolType.handout}
                title={title}
              >
                <Button
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  size="sm"
                  color="black"
                  className="mb-1 mt-2"
                  variant="outlined"
                  startAdornment={<Download />}
                >
                  Download Handout
                </Button>
              </ExportOutputButton>
            )}
            <MarkdownRenderer>{output}</MarkdownRenderer>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  )
}

export default HandoutContent
