import { Button, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ToolType,
  UnitPlanForm,
  UnitPlanGenerateResponse,
  UnitPlanTypeEnum,
} from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useContext } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.unitPlanner

const ProForm = ({
  onGeneration,
}: {
  onGeneration: (data: UnitPlanGenerateResponse) => void
  // onGeneration: (data: APIResponse<RetrievePrompt<toolType>>) => void
}) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<UnitPlanForm>({
    defaultValues: {
      subject: "",
      book: null,
      topic: null,
      grade: "",
      country: "",
      curriculum: "",
      classDuration: "",
      unitPlanType: UnitPlanTypeEnum.topic,
      semesterDuration: null,
      numberOfDays: null,
    },
  })

  const { setPromptDataId } = useContext(AIToolsContext)

  const unitPlanType = watch("unitPlanType")

  const generateUnitPlanPrompt = useMutation({
    mutationFn: async (data: UnitPlanForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateUnitPlanPrompt({
        data: {
          subject: data.subject,
          ...(data.book ? { book: data.book } : {}),
          ...(data.topic ? { topic: data.topic } : {}),
          grade: data.grade,
          country: data.country,
          curriculum: data.curriculum,
          classDuration: `00:${data.classDuration}:00`,
          unitPlanType: data.unitPlanType,
          ...(data.semesterDuration
            ? { semesterDuration: data.semesterDuration }
            : {}),
          ...(data.numberOfDays ? { numberOfDays: data.numberOfDays } : {}),
        },
      })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })
      if (res.unitPlanType === UnitPlanTypeEnum.academicYear)
        generateUnitDivision.mutate(res.id)
      if (res.unitPlanType === UnitPlanTypeEnum.topic)
        generateUnitPlan.mutateAsync(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const generateUnitPlan = useMutation({
    mutationFn: (id: number) =>
      api.aiTools.generateUnitPlan({
        data: {
          promptDataId: id,
          unitPlanType: "topic",
        },
      }),
    onSuccess: res => {
      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const generateUnitDivision = useMutation({
    mutationFn: (promptDataId: number) =>
      api.aiTools.generateUnitDivision({
        data: {
          promptDataId,
        },
      }),
    onSuccess: res => {
      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (unitPlanType === UnitPlanTypeEnum.topic && !data.topic) {
      setError("topic", {
        message: "Required",
      })
    }
    if (unitPlanType === UnitPlanTypeEnum.topic && !data.numberOfDays) {
      setError("numberOfDays", {
        message: "Required",
      })
    }
    if (
      unitPlanType === UnitPlanTypeEnum.academicYear &&
      !data.semesterDuration
    ) {
      setError("semesterDuration", {
        message: "Required",
      })
    }

    await generateUnitPlanPrompt.mutateAsync(data)
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            required
            label="Class/Grade"
            placeholder="Ex: 11th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Curriculum"
            placeholder="Ex: CBSE"
            errors={errors.curriculum?.message}
          />

          <TextField
            required
            label="Subject"
            placeholder="Ex: Economics"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            required
            label="Country"
            placeholder="Ex: India"
            errors={errors.country?.message}
            {...register("country", {
              required: { value: true, message: "Required" },
            })}
          />
        </div>
        {/* <div className="flex flex-wrap items-center gap-2">
          <Typography variant="strong">Unit Plan for:</Typography>
          <Controller
            control={control}
            name="unitPlanType"
            render={({ field: { value, onChange } }) => (
              <RadioGroup
                className="flex items-center gap-2"
                defaultValue={value}
                onValueChange={onChange}
              >
                <RadioGroupItem
                  containerClass="flex space-x-1 items-center"
                  value={UnitPlanTypeEnum.topic}
                  label="Topic"
                />
                <RadioGroupItem
                  containerClass="flex space-x-1 items-center"
                  value={UnitPlanTypeEnum.academicYear}
                  label="Entire Academic Year"
                />
              </RadioGroup>
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div> */}
        {unitPlanType === UnitPlanTypeEnum.academicYear && (
          <TextField
            label="Book Name (Optional)"
            placeholder="Ex: Introduction to Microeconomics"
            errors={errors.book?.message}
            {...register("book")}
          />
        )}
        {unitPlanType === UnitPlanTypeEnum.topic && (
          <>
            <TextField
              required
              label="Topic"
              placeholder="Ex: Statistics"
              errors={errors.topic?.message}
              {...register("topic")}
            />
          </>
        )}
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            required
            label="Class Duration (in minutes)"
            placeholder="Ex: 45"
            helperText="Duration of a single period/lecture"
            errors={errors.classDuration?.message}
            {...register("classDuration", {
              required: { value: true, message: "Required" },
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            })}
          />
          {unitPlanType === UnitPlanTypeEnum.academicYear && (
            <TextField
              required
              label="Complete Duration of the Semester"
              placeholder="Ex: 6"
              errors={errors.semesterDuration?.message}
              {...register("semesterDuration", {
                pattern: {
                  value: /^\d+$/,
                  message: "Only numbers are allowed",
                },
                min: {
                  value: 1,
                  message: "A semester should be at least 1 month",
                },
              })}
            />
          )}
          {unitPlanType === UnitPlanTypeEnum.topic && (
            <TextField
              required
              label="Number of Days"
              placeholder="Ex: 7"
              errors={errors.numberOfDays?.message}
              {...register("numberOfDays", {
                pattern: {
                  value: /^\d+$/,
                  message: "Only numbers are allowed",
                },
              })}
            />
          )}
        </div>

        <Button type="submit" loading={generateUnitPlanPrompt.isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading
        type={toolType}
        open={
          generateUnitPlanPrompt.isLoading ||
          generateUnitDivision.isLoading ||
          generateUnitPlan.isLoading
        }
      />
    </Paper>
  )
}

export default ProForm
