import { CircularProgress, toast, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  HandoutResponse,
  NarrationResponse,
  ToolType,
} from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import useTabs from "components/TabBar/hooks/useTabs"
import Paper from "features/AItools/components/Paper"
import { getToolName, isNumberArray } from "features/AItools/helper"
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../../context"
import InputDisplay from "../../../helpers/InputDisplay"
import GenerateButton from "../../GenerateButton"
import HandoutOutput from "../../HandoutOutput"
import TemplateHandoutsList from "../../HandoutOutput/TemplateHandoutsList"
import NarrationOutput from "../../NarrationOutput"
import TemplateNarrationsList from "../../NarrationOutput/TemplateNarrationsList"
import LessonPlanTabs from "../LessonPlanTabs"
import { generateUuidV4 } from "../ProOutput/Content/helper"
import Content from "./Content"

// If you change name of the tabs, then make sure you change names in getDefaultTab as well.
const getDefaultTab = ({ hasHandout = false, hasNarration = false }) => {
  if (hasHandout) return getToolName(ToolType.handout)
  if (hasNarration) return getToolName(ToolType.narration)
  return getToolName(ToolType.lessonPlan)
}

// If you change name of the tabs, then make sure you change names in getDefaultTab as well.
const initialTabs = [
  {
    name: getToolName(ToolType.lessonPlan),
    content: Content,
  },
]
const BasicOutput = ({
  autoGenerateHandout = false,
  autoGenerateNarration = false,

  resetState,
}: {
  autoGenerateHandout?: boolean
  autoGenerateNarration?: boolean

  resetState: () => void
}) => {
  const { overviewData, currentResponseId } = useContext(AIToolsContext)

  const [hasHandout, setHasHandout] = useState<boolean>(false)
  const [hasNarration, setHasNarration] = useState<boolean>(false)

  const handoutsListQuery = useInfiniteQuery({
    enabled: false,
    // enabled: Boolean(currentResponseId) && !autoGenerateHandout,
    queryKey: queries.aiTools.listHistoryWithOutput(
      ToolType.handout,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentResponseId!
    ).queryKey,
    queryFn: x =>
      api.aiTools.listHistory({
        type: ToolType.handout,
        params: {
          page: x.pageParam || 1,
          lesson_plan_response_id: !isNumberArray(overviewData?.responseIds)
            ? overviewData?.responseIds[0].id
            : -1,
        },
      }),
    onSuccess: data => {
      const allData = data.pages.flatMap(page => page.data)
      if (allData.length > 0) {
        setHasHandout(true)

        // @ts-expect-error this is safe to do
        setTabs(prev => {
          const tabAlreadyExists = prev.some(
            tab => tab.name === getToolName(ToolType.handout)
          )

          if (tabAlreadyExists) return prev

          return [
            ...prev,
            {
              name: getToolName(ToolType.handout),
              content: HandoutOutput,
            },
          ]
        })
      }
    },

    onError: (err: any) => {
      if (err instanceof APIError) {
        console.error(err)
        handleErrors(err)
      }
    },
  })

  const narrationsListQuery = useInfiniteQuery({
    enabled: false,
    // enabled: Boolean(currentResponseId) && !autoGenerateNarration,
    queryKey: queries.aiTools.listHistoryWithOutput(
      ToolType.narration,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentResponseId!
    ).queryKey,
    queryFn: x =>
      api.aiTools.listHistory({
        type: ToolType.narration,
        params: {
          page: x.pageParam || 1,
          lesson_plan_response_id: !isNumberArray(overviewData?.responseIds)
            ? overviewData?.responseIds[0].id
            : -1,
        },
      }),
    onSuccess: data => {
      const allData = data.pages.flatMap(page => page.data)
      if (allData.length > 0) {
        setHasNarration(true)

        // @ts-expect-error this is safe to do
        setTabs(prev => {
          const tabAlreadyExists = prev.some(
            tab => tab.name === getToolName(ToolType.narration)
          )

          if (tabAlreadyExists) return prev

          return [
            ...prev,
            {
              name: getToolName(ToolType.narration),
              content: NarrationOutput,
            },
          ]
        })
      }
    },
    onError: (err: any) => {
      if (err instanceof APIError) {
        console.error(err)
        handleErrors(err)
      }
    },
  })

  const [tabs, setTabs] = useState(initialTabs)
  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: getDefaultTab({
      hasHandout: hasHandout,
      hasNarration: hasNarration,
    }),
  })

  const handoutCallback = useCallback(() => {
    handoutsListQuery.refetch()
    resetState()
    // if (resetForm) resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const narrationCallback = useCallback(() => {
    narrationsListQuery.refetch()
    resetState()
    // if (resetForm) resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const content = useQuery({
    enabled: currentResponseId !== undefined,
    queryKey: queries.aiTools.retrieveHistory(
      ToolType.lessonPlan,
      currentResponseId
    ).queryKey,
    queryFn: () =>
      api.aiTools.retrieveContent<ToolType.lessonPlan>({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
          type: ToolType.lessonPlan,
        },
      }),
    onError: err => {
      if (err instanceof APIError) {
        toast.error(err.message || "Something went wrong")
      }
    },
  })
  useEffect(() => {
    if (content.data) {
      handoutsListQuery.refetch()
      narrationsListQuery.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.data])

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && <InputDisplay data={overviewData} />}

      <LessonPlanTabs
        className="mx-0 border-0 pb-0 pt-1"
        tabs={tabs}
        activeTabName={activeTab.name}
        onClick={state => {
          setActiveTab(state)
        }}
      />

      {activeTab.name === getToolName(ToolType.lessonPlan) && (
        <div
        // className={clsx({
        //   hidden: activeTab.name !== getToolName(ToolType.lessonPlan),
        // })}
        >
          {content.data ? (
            <Content {...content.data} />
          ) : (
            <Paper className="grid place-items-center py-5">
              <CircularProgress />
            </Paper>
          )}
        </div>
      )}
      {handoutsListQuery.data &&
        activeTab.name === getToolName(ToolType.handout) && (
          <div
          // className={clsx({
          //   hidden: activeTab.name !== getToolName(ToolType.handout),
          // })}
          >
            <TemplateHandoutsList
              isFetching={handoutsListQuery.isFetching}
              hasNextPage={handoutsListQuery.hasNextPage}
              fetchNextPage={handoutsListQuery.fetchNextPage}
              handouts={handoutsListQuery.data}
            />
          </div>
        )}
      {narrationsListQuery.data &&
        activeTab.name === getToolName(ToolType.narration) && (
          <div
          // className={clsx({
          //   hidden: activeTab.name !== getToolName(ToolType.narration),
          // })}
          >
            <TemplateNarrationsList
              isFetching={narrationsListQuery.isFetching}
              hasNextPage={narrationsListQuery.hasNextPage}
              fetchNextPage={narrationsListQuery.fetchNextPage}
              narrations={narrationsListQuery.data}
            />
          </div>
        )}
      {currentResponseId && (
        <>
          {activeTab.name === getToolName(ToolType.lessonPlan) &&
            !hasHandout && (
              <GenerateHandoutNudge
                autoGenerate={autoGenerateHandout}
                lessonPlanId={currentResponseId}
                onGenerate={handoutCallback}
              />
            )}
          {activeTab.name === getToolName(ToolType.lessonPlan) &&
            !hasNarration && (
              <GenerateNarrationNudge
                autoGenerate={autoGenerateNarration}
                lessonPlanId={currentResponseId}
                onGenerate={narrationCallback}
              />
            )}
        </>
      )}
    </div>
  )
}

const GenerateHandoutNudge = memo(
  (props: {
    onGenerate: (content: HandoutResponse) => void
    lessonPlanId: number
    autoGenerate: boolean
  }) => {
    const { mutate, isLoading } = useMutation({
      mutationFn: async (data: any) => {
        trackingService.trackEvent("ai_tools_generation_started", {
          tool_type: ToolType.handout,
        })
        const res = await api.aiTools.generateHandout({ data })

        trackingService.trackEvent("ai_tools_generation_success", {
          tool_type: ToolType.handout,
        })

        props.onGenerate(res)
      },
      onError: (err: any) => {
        trackingService.trackEvent("ai_tools_generation_failed", {
          tool_type: ToolType.handout,
          status_code: err?.statusCode,
          error: JSON.stringify(err),
        })
        if (err instanceof APIError) {
          if ((err?.statusCode || 0) > 500) {
            toast.error("We're unable to process your request", {
              description: "Please try again later",
            })
            return
          }
          if (err.errors.message) {
            toast.error(err.errors.message)
            return
          }
        }
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      },
    })

    useEffect(() => {
      if (props.autoGenerate) {
        generate()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.autoGenerate])

    const generate = () => {
      mutate({
        lessonPlanResponse: props.lessonPlanId,
        generatedWithLessonPlan: true,
        groupId: generateUuidV4(),
      })
    }

    return (
      <Paper className="flex flex-wrap items-center justify-between gap-1">
        <Typography variant="title3">Handout for the Lesson</Typography>

        <GenerateButton
          onClick={generate}
          loading={isLoading}
          label="Generate Handout"
        />
      </Paper>
    )
  }
)
GenerateHandoutNudge.displayName = "GenerateHandoutNudge"

const GenerateNarrationNudge = memo(
  (props: {
    onGenerate: (content: NarrationResponse) => void
    lessonPlanId: number
    autoGenerate: boolean
  }) => {
    const { mutate, isLoading } = useMutation({
      mutationFn: async (data: any) => {
        trackingService.trackEvent("ai_tools_generation_started", {
          tool_type: ToolType.narration,
        })
        const res = await api.aiTools.generateNarration({ data })

        trackingService.trackEvent("ai_tools_generation_success", {
          tool_type: ToolType.narration,
        })

        props.onGenerate(res)
      },
      onError: (err: any) => {
        trackingService.trackEvent("ai_tools_generation_failed", {
          tool_type: ToolType.narration,
          status_code: err?.statusCode,
          error: JSON.stringify(err),
        })
        if (err instanceof APIError) {
          if ((err?.statusCode || 0) > 500) {
            toast.error("We're unable to process your request", {
              description: "Please try again later",
            })
            return
          }
          if (err.errors.message) {
            toast.error(err.errors.message)
            return
          }
        }
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      },
    })

    useEffect(() => {
      if (props.autoGenerate) {
        generate()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.autoGenerate])

    const generate = () => {
      mutate({
        lessonPlanResponse: props.lessonPlanId,
        generatedWithLessonPlan: true,
        groupId: generateUuidV4(),
      })
    }

    return (
      <Paper className="flex flex-wrap items-center justify-between gap-1">
        <Typography variant="title3">Narration for the Lesson</Typography>

        <GenerateButton
          onClick={generate}
          loading={isLoading}
          label="Generate Narration"
        />
      </Paper>
    )
  }
)
GenerateNarrationNudge.displayName = "GenerateNarrationNudge"
export default BasicOutput
