import { Button, TextArea, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ActivityPlannerForm,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useContext } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.activityPlanner

const ProForm = ({
  onGeneration,
}: {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ActivityPlannerForm>()
  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: ActivityPlannerForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateActivityPlanner({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })

      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const { isActive, showGetPlusSheet } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!isActive) {
      showGetPlusSheet()
      return
    }

    mutate({ ...data, duration: `00:${data.duration}:00` })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            label="Class/Grade"
            required
            placeholder="Ex: 6th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            label="Curriculum"
            required
            placeholder="Ex: IB"
            errors={errors.curriculum?.message}
          />

          <TextField
            label="Subject"
            required
            placeholder="Ex: Maths"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            label="Duration (in minutes)"
            required
            placeholder="Ex: 30"
            errors={errors.duration?.message}
            {...register("duration", {
              required: {
                value: true,
                message: "Required",
              },
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            })}
          />
        </div>
        <TextArea
          rows={4}
          label="Objective/Description"
          required
          placeholder="Ex: Apply the Pythagorean Theorem to solve problems involving right-angled triangles, including finding missing side lengths, and demonstrate understanding through hands-on activities and real-world examples."
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default ProForm
