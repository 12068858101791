import {
  Button,
  CircularProgress,
  ErrorMessage,
  Select,
  TextField,
  toast,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  RetrievePrompt,
  ToolType,
  VideoSummaryForm,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import useDebounce from "utils/hooks/useDebounce"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"
import TimeRangeSlider from "./TimeRangeSlider"

const LANGUAGE_LIST = [
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
]

const toolType = ToolType.videoSummary

const ProForm = ({
  onGeneration,
}: {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}) => {
  const [duration, setDuration] = useState<string | null>(null)
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    watch,
  } = useForm<VideoSummaryForm>({
    defaultValues: { language: LANGUAGE_LIST[0].value },
  })
  const { setPromptDataId } = useContext(AIToolsContext)
  const videoUrl = useDebounce(watch("url"))

  const retrieveVideoDuration = useMutation({
    mutationFn: async (url: string) => {
      setDuration(null)
      return api.aiTools.retrieveVideoDuration({ data: { url } })
    },
    onSuccess: res => {
      setDuration(res.duration)
    },
    onError: (err: any) => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
      setDuration(null)
    },
  })

  const { isActive, showGetPlusSheet } = useSuraasaPlusContext()

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: VideoSummaryForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateVideoSummary({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })

      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  useEffect(() => {
    if (videoUrl) {
      retrieveVideoDuration.mutate(videoUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl])

  const onSubmit = handleSubmit(async data => {
    if (!isActive) {
      showGetPlusSheet()
      return
    }

    mutate(data)
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <TextField
          required
          label="Video URL"
          endIcon={
            retrieveVideoDuration.isLoading ? <CircularProgress /> : undefined
          }
          placeholder="Ex: https://www.youtube.com/watch?v=4WTtDj23Sec"
          errors={errors.url?.message}
          {...register("url", {
            required: { value: true, message: "Required" },
          })}
        />
        <Controller
          control={control}
          name="language"
          render={({ field: { value, onChange, onBlur } }) => (
            <Select
              required
              errors={errors.language?.message}
              label="language"
              options={LANGUAGE_LIST}
              placeholder="Ex: English"
              mountOnBody
              onBlur={onBlur}
              value={LANGUAGE_LIST.find(c => c.value === value)}
              onChange={val => onChange(val?.value)}
            />
          )}
          rules={{
            required: { value: true, message: "Required" },
          }}
        />
        <Controller
          control={control}
          name="startTime"
          render={({ field: { onChange: onChangeStartTime } }) => (
            <Controller
              control={control}
              name="endTime"
              render={({ field: { onChange: onChangeEndTime } }) => (
                <>
                  <TimeRangeSlider
                    minTime="00:00:00"
                    maxTime={duration}
                    onChangeMin={min => onChangeStartTime(min)}
                    onChangeMax={max => onChangeEndTime(max)}
                    disabled={!videoUrl || !duration}
                  />
                  {(errors.startTime || errors.endTime) && (
                    <ErrorMessage
                      title={
                        errors.startTime?.message || errors.endTime?.message
                      }
                    />
                  )}
                </>
              )}
              rules={{
                required: { value: true, message: "End time is required" },
              }}
            />
          )}
          rules={{
            required: { value: true, message: "Start time is required" },
          }}
        />

        <Button type="submit" loading={isLoading} disabled={!duration}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading
        type={toolType}
        open={isLoading}

        // onClose={() => {
        //   setLoading(false)
        // }}
      />
    </Paper>
  )
}

export default ProForm
