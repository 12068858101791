import {
  Button,
  DialogBody,
  DialogFooter,
  Typography,
} from "@suraasa/placebo-ui"
import React from "react"

const Note = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <DialogBody className="py-2.5">
        <Typography variant="body" className="mb-1">
          <ul className="list-disc ps-2">
            <li className="mb-1">
              Your request will be reviewed by your program manager within 48
              hours.
            </li>
            <li className="mb-1">
              You will be notified via email once your request is approved or
              rejected.
            </li>
            <li className="mb-1">
              Remember to include the complete submission in your re-attempt.
            </li>
          </ul>
        </Typography>
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClick} size="sm">
          I understand
        </Button>
      </DialogFooter>
    </>
  )
}

export default Note
