import {
  Button,
  Checkbox,
  Select,
  TextArea,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  LessonPlanForm,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
} from "features/AItools/helper"
import React, { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.lessonPlan

type Form = LessonPlanForm & {
  generateHandout: boolean
  generateNarration: boolean
}
type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
  onGenerateHandout: () => void
  onGenerateNarration: () => void
}

const BasicForm = (props: Props) => {
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>()

  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: LessonPlanForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateLessonPlan({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      props.onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      } else {
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      }
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (data.generateHandout) {
      props.onGenerateHandout()
    }
    if (data.generateHandout) {
      props.onGenerateNarration()
    }

    mutate(data)
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            required
            label="Class/Grade"
            placeholder="Ex: 10th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Curriculum"
            placeholder="Ex: IB"
            errors={errors.curriculum?.message}
          />
        </div>
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            required
            label="Subject"
            placeholder="Ex: Chemistry"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="duration"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                required
                label="Class Duration"
                errors={errors.duration?.message}
                options={DURATION_MINUTES}
                placeholder="60 minutes"
                value={DURATION_MINUTES.find(c => c.value === value)}
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />
        </div>
        <TextArea
          required
          rows={3}
          label="topic"
          placeholder="Ex: Analyzing the Chemical Properties and Reactions of Acids and Bases, with a Focus on pH, Neutralization, and Their Applications in Everyday Life"
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            {...register("country", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Country"
            placeholder="Ex: United Arab Emirates"
            errors={errors.country?.message}
            helperText="We use this to tailor the lesson plan to your region"
          />
          <div className="flex flex-[50%] shrink-0 flex-col">
            <Controller
              control={control}
              name="bloomTaxonomyLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  required
                  isMulti
                  closeMenuOnSelect={false}
                  label="Bloom's Taxonomy Level"
                  errors={errors.bloomTaxonomyLevel?.message}
                  options={BLOOMS_TAXONOMY_OPTIONS}
                  placeholder="Ex: Analyse, Apply"
                  value={
                    value &&
                    BLOOMS_TAXONOMY_OPTIONS.filter(c => value.includes(c.value))
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val.map(x => x.value))}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
            <Link
              to="https://www.suraasa.com/blog/learning-taxonomies"
              target="_blank"
            >
              <Typography
                className="mt-0.5 text-interactive-500 hover:underline"
                variant="smallBody"
              >
                What is Bloom&apos;s Taxonomy?
              </Typography>
            </Link>
          </div>
        </div>
        <div>
          <div>
            <Controller
              control={control}
              name="generateHandout"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  containerClass="flex space-x-1"
                  label={
                    <div>
                      <Typography>Generate Handout</Typography>
                      <Typography
                        variant="smallBody"
                        className="text-onSurface-500"
                      >
                        Handouts are supplementary materials that help students
                        understand the lesson better.
                      </Typography>
                    </div>
                  }
                  checked={value}
                  onCheckedChange={checked => onChange(checked)}
                />
              )}
            />
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="generateNarration"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  containerClass="flex space-x-1"
                  label={
                    <div>
                      <Typography>Generate Narration</Typography>
                      <Typography
                        variant="smallBody"
                        className="text-onSurface-500"
                      >
                        Narration is a concise guide outlining how a teacher
                        will teach, including objectives, strategies, materials,
                        assessments, and closure.
                      </Typography>
                    </div>
                  }
                  checked={value}
                  onCheckedChange={checked => onChange(checked)}
                />
              )}
            />
          </div>
        </div>
        <Button type="submit" loading={isLoading}>
          Generate Lesson Plan
        </Button>
      </form>
      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default BasicForm
