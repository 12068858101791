import { Container } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import ErrorPage404 from "components/ErrorPage404"
import { isNumberArray } from "features/AItools/helper"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import HistorySidebar from "../History/HistorySidebar"
import ActivityPlannerGenerator from "./ActivityPlannerGenerator"
import AssignmentGenerator from "./AssignmentGenerator"
import { AIToolsContext } from "./context"
import HandoutGenerator from "./HandoutGenerator"
import LessonGenerator from "./LessonPlanGenerator"
import NarrationGenerator from "./NarrationGenerator"
import QuizGenerator from "./QuizGenerator"
import RubricsGenerator from "./RubricsGenerator"
import SubjectiveAssessmentGenerator from "./SubjectiveAssessmentGenerator"
import UnitPlanGenerator from "./UnitPlanGenerator"
import VideoSummaryGenerator from "./VideoSummaryGenerator"

const Generator = ({ type }: { type: ToolType }) => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const [temporaryHistory, setTemporaryHistory] = useState<ListPromptHistory[]>(
    []
  )

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const [promptDataId, setPromptDataId] = useState<number | undefined | null>()

  const [responseId, setResponseId] = useState<number | undefined>()
  const [responses, setResponses] = useState<number[] | undefined>()

  const [openHistorySideBar, setOpenHistorySideBar] = useState(false)

  const resetState = () => {
    setPromptDataId(null)
    setResponseId(undefined)
    setResponses(undefined)
  }

  const { data: overviewData, refetch: refetchOverviewData } = useQuery({
    queryKey: queries.aiTools.promptData(type, promptDataId).queryKey,
    queryFn: () => {
      // resetState()
      return api.aiTools.getPromptData({
        urlParams: {
          type: type,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: promptDataId!,
        },
      })
    },
    enabled: Boolean(promptDataId),
    onSuccess: res => {
      if (res.responseIds && isNumberArray(res.responseIds)) {
        setResponses(res.responseIds)
        setResponseId(res.responseIds[0])
      }
    },
  })

  useEffect(() => {
    if (id) {
      setPromptDataId(parseInt(id))
      setResponses(undefined)
      setResponseId(undefined)
    }
  }, [id])

  if (!isAIToolsEnabled) {
    return <ErrorPage404 />
  }

  return (
    <AIToolsContext.Provider
      value={{
        promptDataId,
        setPromptDataId,
        responseIds: responses,
        setResponseIds: setResponses,
        currentResponseId: responseId,
        setCurrentResponseId: setResponseId,
        onResponseIdChange: id => {
          setResponseId(id)
        },
        openHistorySideBar,
        setOpenHistorySideBar,
        type,
        overviewData,
        resetState,
        refetchOverviewData() {
          refetchOverviewData()
        },
      }}
    >
      <div className="relative h-full min-h-screen pb-10">
        <div className="lg:hidden">
          <HistorySidebar
            slideIn={openHistorySideBar}
            onClose={() => setOpenHistorySideBar(false)}
            type={type}
            isGenerator
            tempHistory={temporaryHistory}
          />
        </div>
        {/* {JSON.stringify(
          {
            currentResponseId: responseId,
            responseIds: responses,
            promptDataId,
          },
          null,
          "\n"
        )} */}

        <div
          className="absolute left-0 z-10 hidden lg:block"
          style={{
            height: "100%",
            top: 0,
            overflow: "auto",
          }}
        >
          <HistorySidebar
            positioned
            isGenerator
            type={type}
            tempHistory={temporaryHistory}
          />
        </div>

        <div className="flex">
          <div className="w-full">
            <Container className="lg:!pl-[calc(420px+32px)]">
              <>
                {type === ToolType.lessonPlan && (
                  <LessonGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.rubrics && (
                  <RubricsGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.quiz && (
                  <QuizGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.handout && (
                  <HandoutGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.narration && (
                  <NarrationGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.subjectiveAssessment && (
                  <SubjectiveAssessmentGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.assignment && (
                  <AssignmentGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.activityPlanner && (
                  <ActivityPlannerGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.videoSummary && (
                  <VideoSummaryGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
                {type === ToolType.unitPlanner && (
                  <UnitPlanGenerator
                    id={promptDataId ? promptDataId.toString() : undefined}
                    onGeneration={data =>
                      setTemporaryHistory([data, ...temporaryHistory])
                    }
                  />
                )}
              </>
            </Container>
          </div>
        </div>
      </div>
    </AIToolsContext.Provider>
  )
}

export default Generator
