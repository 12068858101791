import { Button, Select, TextArea, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  RetrievePrompt,
  RubricsForm,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName, pointScaleOptions } from "features/AItools/helper"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.rubrics

const BasicForm = ({
  onGeneration,
}: {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}) => {
  const {
    register,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<RubricsForm>({})

  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: RubricsForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateRubrics({ data })
    },
    onSuccess: async res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (!data.additionalCustomisation) {
      data.additionalCustomisation = undefined
    }
    mutate(data)
  })
  return (
    <>
      <Paper>
        <form onSubmit={onSubmit} className="flex flex-col gap-2">
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            <TextField
              {...register("grade", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Class/Grade"
              placeholder="Ex: 11th Grade"
              errors={errors.grade?.message}
            />
            <TextField
              {...register("curriculum", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Curriculum"
              placeholder="Ex: ICSE"
              errors={errors.curriculum?.message}
            />
          </div>

          <TextField
            {...register("objective", {
              required: { value: true, message: "Required" },
            })}
            required
            placeholder="Ex: Evaluate Answers for Simple Harmonic Motion"
            label="Standard/Objective"
            errors={errors.objective?.message}
          />
          <TextArea
            {...register("assignmentDescription", {
              required: { value: true, message: "Required" },
            })}
            rows={3}
            required
            label="Description"
            placeholder={`Ex: Ask students to define SHM in their own words.\nHave students identify examples of SHM in everyday life.\nGive students a set of problems to solve using the equations of SHM`}
            errors={errors.assignmentDescription?.message}
          />
          <Controller
            control={control}
            name="pointOfScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                required
                errors={errors.pointOfScale?.message}
                label="Performance Level"
                options={pointScaleOptions}
                placeholder="Ex: 5"
                mountOnBody
                onBlur={onBlur}
                value={pointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <TextArea
            {...register("additionalCustomisation")}
            label="Additional Customisation (optional)"
            placeholder="Ex: Marking should be strict and learners should be able to apply the concepts in real life scenarios"
            errors={errors.additionalCustomisation?.message}
          />
          <Button type="submit" loading={isLoading}>
            Generate {getToolName(toolType)}
          </Button>
        </form>
      </Paper>
      <AiToolsLoading type={toolType} open={isLoading} />
    </>
  )
}

export default BasicForm
