import { Accordion } from "@suraasa/placebo-ui"
import { isNumberArray } from "features/AItools/helper"
import React, { useContext, useState } from "react"

import { AIToolsContext } from "../../../context"
import InputDisplay from "../../../helpers/InputDisplay"
import Content from "./Content"

const ProOutput = () => {
  const { overviewData } = useContext(AIToolsContext)
  const [expandValue, setExpandValue] = useState<string | null>(null)
  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && <InputDisplay data={overviewData} />}
      <Accordion
        type="single"
        value={expandValue || ""}
        className="flex flex-col gap-2"
      >
        {overviewData?.responseIds &&
          !isNumberArray(overviewData.responseIds) &&
          overviewData.responseIds.map((responseId, index) => (
            <Content
              sequence={index + 1}
              key={index}
              responseIds={responseId.responseIds}
              isOpen={expandValue === `item-${index + 1}`}
              onClick={state => {
                setExpandValue(state)
              }}
            />
          ))}
      </Accordion>
    </div>
  )
}

export default ProOutput
