import {
  Button,
  DialogBody,
  DialogFooter,
  ErrorMessage,
  RadioGroup,
  RadioGroupItem,
  TextArea,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { UserReattemptCounterType } from "api/resources/assessments/types"
import { calculateReattemptsLeft } from "features/ReattemptCount"
import { InfoCircle } from "iconoir-react"
import { Controller, useForm } from "react-hook-form"
import { mapErrors, pluralize } from "utils/helpers"

const otherOptionValue = "other"

const Reason = ({
  onSubmit,
  type,
  learningContentId,
  userPlannerItemId,
  userReattemptCounter,
}: {
  onSubmit: () => void
  type: "assessment" | "assignment"
  learningContentId?: string
  userPlannerItemId?: number
  userReattemptCounter: UserReattemptCounterType
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<{
    reason: string
    customReason: string
  }>()

  const reason = watch("reason")

  const { mutateAsync } = useMutation({
    mutationFn: (reason: string) =>
      api.batch.createReattemptRequest({
        data: {
          userPlannerItemId: userPlannerItemId,
          reason: reason,
          learningContentId: learningContentId,
          learningContentType: type,
        },
      }),
    onSuccess: () => {
      onSubmit()
    },
    onError: error => {
      mapErrors(setError, error, [["reason"]])
    },
  })

  const onSubmitForm = handleSubmit(async data => {
    let hasError = false
    if (data.reason === otherOptionValue && !data.customReason) {
      hasError = true
      setError("customReason", { message: "Required" })
    }

    if (hasError) return
    const reason =
      data.reason === otherOptionValue ? data.customReason : data.reason

    await mutateAsync(reason)
  })

  const reattemptsLeft = calculateReattemptsLeft(userReattemptCounter)

  return (
    <>
      <DialogBody className="py-2.5">
        <Typography variant="strong" className="mb-1.5">
          Please tell us the reason for reattempt request
        </Typography>
        <form onSubmit={onSubmitForm} id="reason-form">
          <Controller
            control={control}
            name="reason"
            render={({ field: { value, onChange } }) => (
              <RadioGroup
                className="mb-2"
                defaultValue={value}
                onValueChange={reason => {
                  if (reason !== otherOptionValue) {
                    setValue("customReason", "")
                  }
                  onChange(reason)
                }}
              >
                <RadioGroupItem
                  containerClass="flex items-center gap-1"
                  label="Failed my last attempt"
                  value="Failed my last attempt"
                />
                <RadioGroupItem
                  containerClass="flex items-center gap-1"
                  label="For score improvement"
                  value="For score improvement"
                />
                <RadioGroupItem
                  containerClass="flex items-center gap-1"
                  label="Other Reason"
                  value={otherOptionValue}
                />
              </RadioGroup>
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          {errors.reason && <ErrorMessage title={errors.reason.message} />}
          {reason === otherOptionValue && (
            <TextArea
              {...register("customReason")}
              errors={errors.customReason?.message}
              rows={5}
              label="Other Reason for Reattempt"
              className="mb-1.5"
            />
          )}
        </form>
        {reattemptsLeft !== undefined && reattemptsLeft - 1 >= 0 && (
          <div className="flex gap-1 rounded-lg bg-warning-100 p-1.5 text-warning-900">
            <InfoCircle className="shrink-0" />
            <Typography variant="smallBody">
              Upon approval of this request, you will have{" "}
              {pluralize("re-attempt", reattemptsLeft - 1)} left.
            </Typography>
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          type="submit"
          size="sm"
          form="reason-form"
          loading={isSubmitting}
        >
          Submit Request
        </Button>
      </DialogFooter>
    </>
  )
}

export default Reason
