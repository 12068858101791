import { Button, Select, TextArea, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  AssignmentForm,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName, pointScaleOptions } from "features/AItools/helper"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.assignment

type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}

const BasicForm = ({ onGeneration }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AssignmentForm>()
  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: AssignmentForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateAssignment({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })

      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    mutate(data)
  })
  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className=" grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            required
            label="Class/Grade"
            placeholder="Ex: 10th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Curriculum"
            placeholder="Ex: CBSE"
            errors={errors.curriculum?.message}
          />

          <TextField
            required
            label="Subject"
            placeholder="Ex: Economics"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="rubricScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                required
                errors={errors.rubricScale?.message}
                label="Rubric Point Scale"
                options={pointScaleOptions}
                placeholder="Ex: 5"
                mountOnBody
                onBlur={onBlur}
                value={pointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <TextArea
          required
          rows={6}
          label="Objective/Description"
          placeholder={`Ex: Define resources and their contribution to economic development.\nRecognize the different types of resources.\nUnderstand the importance of resource planning and management.\nEvaluate the role of technology in sustainable resource management.\nExamine resource-related issues and challenges in contemporary India.`}
          errors={errors.assignmentObjectives?.message}
          {...register("assignmentObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default BasicForm
