import { CircularProgress } from "@suraasa/placebo-ui"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"
import { usePlusFeature } from "features/AItools/PRO/utils/usePlusFeature"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import Heading from "../../Heading"
import TogglePro from "../../TogglePro"
import { AIToolsContext } from "../context"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import LessonPlanOutput from "../GenerationOutput/LessonPlanOutput"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"

const toolType = ToolType.lessonPlan

const LessonGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()

  const [enablePlusMode, setEnablePlusMode] = usePlusFeature()
  const [generateHandout, setGenerateHandout] = useState(false)
  const [generateNarration, setGenerateNarration] = useState(false)

  const {
    promptDataId,
    overviewData,
    setCurrentResponseId,
    setPromptDataId,
    setResponseIds,
    currentResponseId,
  } = useContext(AIToolsContext)

  useEffect(() => {
    if (!overviewData) return

    if (
      overviewData.responseIds &&
      overviewData.responseIds.length > 0 &&
      typeof overviewData.responseIds[0] !== "number"
    ) {
      setCurrentResponseId(overviewData.responseIds[0].responseIds[0])
      setResponseIds(overviewData.responseIds[0].responseIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewData])

  if (id && !overviewData) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (id && overviewData) {
    return (
      <>
        {/* <ContentActions
          onBack={() => {
            navigate(routes.aiTools.lessonPlan, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={toolType}
          title={overviewData?.title}
        /> */}
        {promptDataId && (
          <ContentActions
            onBack={() => {
              navigate(routes.aiTools.lessonPlan, { replace: true })
              setPromptDataId(null)
            }}
            currentResponseId={promptDataId}
            toolType={ToolType.lessonPlan}
            title={overviewData?.title}
            responseIds={overviewData.responseIds.map(item =>
              item.responseIds.includes(currentResponseId)
                ? currentResponseId
                : item.responseIds[0]
            )}
          />
        )}
        <LessonPlanOutput
          autoGenerateHandout={generateHandout}
          autoGenerateNarration={generateNarration}
          resetState={() => {
            setGenerateHandout(false)
            setGenerateNarration(false)
          }}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(toolType)} Generator`}
        subtitle="Craft comprehensive lesson plans effortlessly, using just a few keywords."
      />
      <TogglePro
        isPro={enablePlusMode}
        onChange={state => setEnablePlusMode(state)}
        className="mb-2"
      />
      {enablePlusMode ? (
        <ProForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.lessonPlanPro,
            })
          }}
        />
      ) : (
        <BasicForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.lessonPlan,
            })
          }}
          onGenerateHandout={() => {
            setGenerateHandout(true)
          }}
          onGenerateNarration={() => {
            setGenerateNarration(true)
          }}
        />
      )}
    </>
  )
}

export default LessonGenerator
