import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import {
  ReattemptType,
  UserReattemptCounterType,
} from "api/resources/assessments/types"
import { ReattemptRequestStatusEnum } from "api/resources/assignments/types"
import clsx from "clsx"
import { calculateReattemptsLeft } from "features/ReattemptCount"
import { InfoCircle } from "iconoir-react"
import { pluralize } from "utils/helpers"

const ReattemptBanner = ({
  onClick,
  forceReattempt,
  reattemptRequest,
  reattemptType,
  userReattemptCounter,
}: {
  onClick: () => void
  forceReattempt?: boolean
  reattemptRequest?: ReattemptRequestStatusEnum | null
  reattemptType?: ReattemptType | undefined
  userReattemptCounter?: UserReattemptCounterType | null | undefined
}) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const displayReattemptCount = (count: number) => (
    <Typography className="inline" variant="smallBody">
      You have {pluralize("re-attempt", count)} left.
    </Typography>
  )

  const reAttemptsLeft = calculateReattemptsLeft(userReattemptCounter)
  return (
    <div
      className={clsx({
        "bg-highlight-50": !forceReattempt,
        "bg-critical-50": forceReattempt,
      })}
    >
      <Container className="py-1">
        {reattemptType === ReattemptType.ON_REQUEST ? (
          <>
            {reattemptRequest === ReattemptRequestStatusEnum.UNDER_REVIEW ? (
              <Typography
                color={forceReattempt ? "critical.900" : "highlight.900"}
                variant="strongSmallBody"
                className="flex items-center gap-1 py-1"
              >
                <InfoCircle />
                <span>You have requested a reattempt for this submission</span>
              </Typography>
            ) : (
              <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
                <Typography
                  color={forceReattempt ? "critical.900" : "highlight.900"}
                  variant="strongSmallBody"
                >
                  {forceReattempt
                    ? "You did not meet the passing criteria, please request for a reattempt"
                    : "Do you want to improve your score?"}
                  {reAttemptsLeft !== undefined &&
                    reAttemptsLeft > 0 &&
                    displayReattemptCount(reAttemptsLeft)}
                </Typography>
                <Button
                  fullWidth={isXsDown}
                  onClick={onClick}
                  color={forceReattempt ? "critical" : "primary"}
                  variant="text"
                >
                  Request a Reattempt
                </Button>
              </div>
            )}
          </>
        ) : (
          <Typography
            color={forceReattempt ? "critical.900" : "highlight.900"}
            variant="strongSmallBody"
            className="flex items-center gap-1 py-1"
          >
            {forceReattempt
              ? "You did not meet the passing criteria, please reattempt"
              : "Do you want to improve your score?"}
            {reAttemptsLeft !== undefined &&
              reAttemptsLeft > 0 &&
              displayReattemptCount(reAttemptsLeft)}
          </Typography>
        )}
      </Container>
    </div>
  )
}

export default ReattemptBanner
