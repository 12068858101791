import { Button, Select, TextArea, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  RetrievePrompt,
  RubricsProForm,
  RubricType,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import {
  criteriaLevelOptions,
  getToolName,
  proPointScaleOptions,
  rubricsTypeOptions,
} from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.rubricsPro

type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}

const ProForm = ({ onGeneration }: Props) => {
  const {
    register,
    control,
    setError,
    resetField,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<RubricsProForm>({})

  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: RubricsProForm) => {
      console.log(data)
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateRubricsPro({ data })
    },
    onSuccess: async res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })
      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    ensurePlusAccess(async () => {
      mutate(data)
    })
  })

  const rubricType = watch("rubricType")

  useEffect(() => {
    resetField("pointOfScale")
    clearErrors("pointOfScale")
    setValue("pointOfScale", undefined)

    resetField("criteriaLevel")
    clearErrors("criteriaLevel")
    setValue("criteriaLevel", undefined)
  }, [rubricType])

  return (
    <>
      <Paper>
        <form onSubmit={onSubmit} className="flex flex-col gap-2">
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <TextField
              {...register("grade", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Class/Grade"
              placeholder="Ex: 6th Grade"
              errors={errors.grade?.message}
            />
            <TextField
              {...register("curriculum", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Curriculum"
              placeholder="Ex: CBSE"
              errors={errors.curriculum?.message}
            />

            <TextField
              {...register("objective", {
                required: { value: true, message: "Required" },
              })}
              required
              placeholder="Ex: Evaluate creativity of stories written using literary techniques"
              label="Standard/Objective"
              errors={errors.objective?.message}
            />
            <Controller
              control={control}
              name="rubricType"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  required
                  errors={errors.rubricType?.message}
                  label="Rubric Type"
                  options={rubricsTypeOptions}
                  placeholder="Ex: Analytic"
                  mountOnBody
                  onBlur={onBlur}
                  value={rubricsTypeOptions.find(c => c.value === value)}
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>

          {rubricType && rubricType !== RubricType.singlePoint && (
            <Controller
              control={control}
              name="pointOfScale"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  required
                  errors={errors.pointOfScale?.message}
                  label="Performance Level"
                  options={proPointScaleOptions}
                  placeholder="Ex: 5"
                  mountOnBody
                  onBlur={onBlur}
                  value={
                    proPointScaleOptions.find(c => c.value === value) ?? null
                  }
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          )}

          {rubricType && rubricType !== RubricType.holistic && (
            <Controller
              control={control}
              name="criteriaLevel"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  errors={errors.criteriaLevel?.message}
                  label="Criteria Level"
                  options={criteriaLevelOptions}
                  placeholder="Ex: 5"
                  mountOnBody
                  onBlur={onBlur}
                  value={
                    criteriaLevelOptions.find(c => c.value === value) ?? null
                  }
                  onChange={val => onChange(val?.value)}
                />
              )}
            />
          )}

          <TextArea
            {...register("assignmentDescription", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Description"
            placeholder={`Ex: Students will write original short stories inspired by a theme or prompt, using literary techniques like imagery and dialogue. They should focus on clear structure, character/setting development, and personal voice. The assignment includes drafting, revising, and editing. Rubrics should assess creativity, technique, structure, and revisions.`}
            errors={errors.assignmentDescription?.message}
            rows={6}
          />

          <Button type="submit" loading={isLoading}>
            Generate {getToolName(toolType)}
          </Button>
        </form>
      </Paper>
      <AiToolsLoading type={toolType} open={isLoading} />
    </>
  )
}

export default ProForm
