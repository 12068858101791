import { LessonPlanResponse } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import React from "react"

import MarkdownRenderer from "../../../MarkdownRenderer"
import RegenerateOutput from "../../../RegenerateOutput"

const Content = ({ ...props }: LessonPlanResponse) => {
  return (
    <Paper
      endSlot={
        <RegenerateOutput
          copyText={props.output}
          isPositiveResponse={props.isPositiveResponse}
        />
      }
    >
      <MarkdownRenderer>{props.output}</MarkdownRenderer>
    </Paper>
  )
}

export default Content
