import { toast, Typography } from "@suraasa/placebo-ui"
import { InfiniteData, useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { PaginatedAPIResponse } from "api/types"
import { APIError } from "api/utils"
import { getToolName, isNumberArray } from "features/AItools/helper"
import { useState } from "react"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import NarrationContent from "../NarrationContent"

const TemplateNarrationsList = ({
  isFetching,
  fetchNextPage,
  hasNextPage,
  narrations: data,
}: {
  isFetching: boolean
  fetchNextPage: () => void
  hasNextPage?: boolean
  narrations?: InfiniteData<PaginatedAPIResponse<ListPromptHistory[]>>
}) => {
  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const allData = data?.pages.flatMap(page => page.data)

  const firstData = allData && allData.length > 0 ? allData[0] : null

  const hasOnlyOneHandout = allData && allData.length === 1

  return (
    <>
      <FetchNarrationContent
        id={firstData?.promptDataId}
        defaultOpen
        sequence={hasOnlyOneHandout ? undefined : 0}
      />
      <div className="mb-4 space-y-4">
        <div>
          {allData &&
            allData.filter(x => x.generatedWithLessonPlan !== true).length >
              0 && (
              <Typography
                variant="smallBody"
                className="mb-2 text-onSurface-500"
              >
                The Narrations below were generated separately by linking this
                Lesson Plan in the <b>Narration Generator.</b>
              </Typography>
            )}

          {allData &&
            allData
              .filter(x => x.generatedWithLessonPlan !== true)
              .map((prompt, index) => {
                const isLastItem = index === allData.length - 1
                return (
                  <div key={prompt.promptDataId} ref={isLastItem ? ref : null}>
                    <FetchNarrationContent
                      defaultOpen
                      sequence={hasOnlyOneHandout ? undefined : index + 1}
                      id={prompt.promptDataId}
                    />
                  </div>
                )
              })}
        </div>
      </div>
    </>
  )
}

const toolType = ToolType.narration

const FetchNarrationContent = ({
  id,
  defaultOpen,
  sequence,
}: {
  id?: number
  defaultOpen?: boolean
  sequence: number | undefined
}) => {
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const { data: overviewData, refetch } = useQuery({
    queryKey: queries.aiTools.promptData(toolType, id).queryKey,
    queryFn: () =>
      api.aiTools.getPromptData({
        urlParams: {
          type: toolType,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: id!,
        },
      }),
    enabled: Boolean(id),
    onSuccess: res => {
      if (res.responseIds && !isNumberArray(res.responseIds))
        setCurrentResponseId(res.responseIds[0].responseIds[0])
    },
  })

  const { data } = useQuery({
    enabled: Boolean(currentResponseId),
    queryKey: queries.aiTools.retrieveHistory(toolType, currentResponseId)
      .queryKey,
    queryFn: () =>
      api.aiTools.retrieveContent<typeof toolType>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { type: toolType, id: currentResponseId! },
      }),
  })
  const { mutate: regenerateResponse, isLoading: regenIsLoading } = useMutation(
    {
      mutationFn: (data: string) => {
        return api.aiTools.regenerateResponse({
          urlParams: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: currentResponseId!,
            type: toolType,
          },
          data: {
            regenerateInstruction: data,
          },
        })
      },
      onSuccess: () => {
        refetch()
      },
      onError: err => {
        if (err instanceof APIError) {
          if ((err?.statusCode || 0) > 500) {
            toast.error("We're unable to process your request", {
              description: "Please try again later",
            })
            return
          }
          if (err.errors.message) {
            toast.error(err.errors.message)
            return
          }
        } else {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
        }
      },
    }
  )

  const title =
    sequence !== undefined
      ? `${getToolName(toolType)} ${sequence + 1}: ${overviewData?.title}`
      : overviewData?.title

  return (
    <div>
      {overviewData && data && (
        <NarrationContent
          showDownloadButton
          promptDataId={data.promptDataId}
          isPositiveResponse={data.isPositiveResponse}
          defaultOpen={defaultOpen}
          id={overviewData.id}
          output={data.output}
          title={title}
          onResponseIdChange={number => {
            setCurrentResponseId(number)
          }}
          responseIds={
            overviewData.responseIds && !isNumberArray(overviewData.responseIds)
              ? overviewData.responseIds[0].responseIds
              : []
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentResponseId={currentResponseId!}
          type={toolType}
          regenerate={{
            regenerateResponse: regenerateResponse,
            isLoading: regenIsLoading,
          }}
        />
      )}
    </div>
  )
}

export default TemplateNarrationsList
