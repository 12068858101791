import { CircularProgress, toast, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import { AIToolsContext } from "features/AItools/components/Generator/context"
import { getToolName, isNumberArray } from "features/AItools/helper"
import { Notes } from "iconoir-react"
import { useContext, useState } from "react"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import GenerateButton from "../../../GenerateButton"
import MarkdownRenderer from "../../../MarkdownRenderer"
import RegenerateOutput from "../../../RegenerateOutput"
import { generateUuidV4 } from "./helper"

const toolType = ToolType.handout

const Output = ({ promptDataId }: { promptDataId: number }) => {
  const [responses, setResponses] = useState<number[]>([])
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )

  const overviewData = useQuery({
    queryKey: queries.aiTools.promptData(toolType, promptDataId).queryKey,
    queryFn: () =>
      api.aiTools.getPromptData({
        urlParams: {
          type: toolType,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: promptDataId!,
        },
      }),
    enabled: Boolean(promptDataId),
    onSuccess: res => {
      if (res.responseIds && !isNumberArray(res.responseIds)) {
        setResponses(res.responseIds[0].responseIds)
        setCurrentResponseId(res.responseIds[0].responseIds[0])
      }
    },
  })

  const { data, isLoading } = useQuery({
    enabled: Boolean(currentResponseId),
    queryKey: queries.aiTools.retrieveHistory(toolType, currentResponseId)
      .queryKey,
    queryFn: async () => {
      const res = await api.aiTools.retrieveContent<typeof toolType>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { type: toolType, id: currentResponseId! },
      })
      return res
    },
  })

  const { mutate: regenerateResponse, isLoading: regenIsLoading } = useMutation(
    {
      mutationFn: (data: string) => {
        return api.aiTools.regenerateResponse({
          urlParams: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: currentResponseId!,
            type: toolType,
          },
          data: {
            regenerateInstruction: data,
          },
        })
      },
      onSuccess: () => {
        overviewData.refetch()
      },
    }
  )

  if (overviewData.isLoading || isLoading) {
    return (
      <div className="grid place-content-center py-5">
        <CircularProgress size="md" />
      </div>
    )
  }
  return (
    <>
      {!currentResponseId && data && (
        <div className="grid place-items-center py-4">
          <CircularProgress />
        </div>
      )}
      {currentResponseId && data && (
        <>
          <div className="px-4">
            <MarkdownRenderer showTitle>{data.output}</MarkdownRenderer>
          </div>
          <RegenerateOutput
            responseIds={responses}
            currentResponseId={currentResponseId}
            refetchOverviewData={overviewData.refetch}
            copyText={data.output || ""}
            isPositiveResponse={data.isPositiveResponse}
            regenerate={{
              regenerateResponse: regenerateResponse,
              isLoading: regenIsLoading,
            }}
            type={ToolType.handout}
            onResponseIdChange={id => {
              setCurrentResponseId(id)
            }}
          />
        </>
      )}
    </>
  )
}

const HandoutContent = () => {
  const {
    currentResponseId: currentLessonPlanResponseId,
    responseIds: lessonPlanResponseIds,
  } = useContext(AIToolsContext)

  const firstResponseId = lessonPlanResponseIds
    ? lessonPlanResponseIds[lessonPlanResponseIds.length - 1]
    : -1

  const [promptDataId, setPromptDataId] = useState<number | null>(null)

  const handoutsListQuery = useInfiniteQuery({
    queryKey: queries.aiTools.listHistoryWithOutput(toolType, firstResponseId)
      .queryKey,
    queryFn: x =>
      api.aiTools.listHistory({
        type: toolType,
        params: {
          page: x.pageParam || 1,
          lesson_plan_response_id: firstResponseId,
        },
      }),
    onSuccess: data => {
      const allData = data.pages.flatMap(page => page.data.flatMap(i => i))
      if (allData.length > 0) {
        setPromptDataId(allData[0].promptDataId)
      }
    },

    onError: (err: any) => {
      if (err instanceof APIError) {
        console.error(err)
        handleErrors(err)
      }
    },
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })

      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      return api.aiTools.generateHandout({
        data: {
          lesson_plan_response: currentLessonPlanResponseId,
          generated_with_lesson_plan: false,
          group_id: generateUuidV4(),
        },
      })
    },
    onSuccess: () => {
      handoutsListQuery.refetch()
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const allData = handoutsListQuery.data?.pages.flatMap(page => page.data)

  if (!promptDataId && handoutsListQuery.isLoading) {
    return (
      <div className="grid place-content-center py-5">
        <CircularProgress size="md" />
      </div>
    )
  }

  if (!promptDataId && allData?.length === 0) {
    return (
      <div className="grid place-content-center py-5">
        <div className="flex flex-col items-center gap-2">
          <div className="grid size-15 place-items-center rounded-full bg-interactive-50 text-interactive-500">
            <Notes className="size-10" strokeWidth={1} />
          </div>
          <Typography variant="title3" className="max-w-[400px] text-center">
            Looks like you haven’t generated any {getToolName(toolType)} for
            this Lesson Plan.
          </Typography>
          <GenerateButton
            onClick={() => {
              mutate()
            }}
            loading={isLoading}
            label="Generate"
          />
        </div>
      </div>
    )
  }

  return promptDataId ? <Output promptDataId={promptDataId} /> : null
}

export default HandoutContent
