import { toast } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import Heading from "features/AItools/components/Heading"
import TogglePro from "features/AItools/components/TogglePro"
import { getToolName } from "features/AItools/helper"
import { usePlusFeature } from "features/AItools/PRO/utils/usePlusFeature"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import { AIToolsContext } from "../../context"
import ContentActions from "../../GenerationOutput/Export/ContentActions"
import SubjectiveAssessmentOutput from "../../GenerationOutput/SubjectiveAssessmentOutput"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"

type Props = {
  openLessonPlanSelector?: boolean
  onGeneration: (data: ListPromptHistory) => void
  id?: string
}

const SubjectiveAssessmentForm = ({
  openLessonPlanSelector = false,
  onGeneration,
}: Props) => {
  const [enableProMode, setEnableProMode] = usePlusFeature()
  const { currentResponseId, promptDataId, setPromptDataId, overviewData } =
    useContext(AIToolsContext)

  const navigate = useNavigate()

  const content = useQuery({
    enabled: currentResponseId !== undefined,
    queryKey: queries.aiTools.retrieveHistory(
      ToolType.subjectiveAssessment,
      currentResponseId
    ).queryKey,
    queryFn: async () =>
      api.aiTools.retrieveContent<ToolType.subjectiveAssessment>({
        urlParams: {
          type: ToolType.subjectiveAssessment,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
        },
      }),
  })

  useEffect(() => {
    if (content.error instanceof APIError) {
      toast.error(
        content.error.message ||
          `Unable to fetch ${getToolName(ToolType.subjectiveAssessment)}`
      )
    }
  }, [content.error])

  if (content.data && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.subjectiveAssessment, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={ToolType.subjectiveAssessment}
          title={overviewData?.title}
        />
        <SubjectiveAssessmentOutput {...content.data} />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.subjectiveAssessment)} Generator`}
        subtitle="Craft customised subjective evaluations, ensuring a comprehensive learning experience."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm
          openLessonPlanSelector={openLessonPlanSelector}
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.subjectiveAssessmentPro,
            })
          }}
        />
      ) : (
        <BasicForm
          openLessonPlanSelector={openLessonPlanSelector}
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.subjectiveAssessmentPro,
            })
          }}
        />
      )}
    </>
  )
}

export default SubjectiveAssessmentForm
