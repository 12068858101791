import { useLayoutEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useSuraasaPlusContext } from "../context/SuraasaPlusContext"

export const usePlusFeature = (defaultValue = false) => {
  const [enablePlus, setEnablePlus] = useState(defaultValue)

  const { ensurePlusAccess, isActive } = useSuraasaPlusContext()

  const [searchParams] = useSearchParams()

  useLayoutEffect(() => {
    if (isActive) {
      setEnablePlus(true)
    }
  }, [isActive])

  useLayoutEffect(() => {
    if (
      searchParams.get("model") === "pro" ||
      searchParams.get("model") === "plus"
    ) {
      ensurePlusAccess(async () => {
        setEnablePlus(true)
      })
    }
  }, [])

  return [enablePlus, setEnablePlus] as const
}
