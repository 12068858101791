import { AttemptResult, AttemptStatus } from "../assessments/types"

export type LearningItemDetails = {
  id: number
  uuid: string
  name: string
  description: string
  isActive: boolean
  slug: string
} & (
  | {
      type: "course"
      image: string | null
      suraasaId: string | null
      courseCredits: string
      subjectCategory: string | null
      language: string
      publisher: string | null
      validity: number | null
      instructions: string | null
      duration: number
      featured: boolean
      level: string
      syllabus: string | null
      glh: number | null
      qualification: string | null
      courseType: string | null
    }
  | {
      type: "qualification"
      qualificationLevel: string
      duration: number
      brochure: string | null
      iqaRequired: boolean
      iqaCriteria: number | null
      bannerImage: string | null
    }
  | {
      type: "certification"
      image: string | null
      bannerImage: string | null
      duration: number
      brochure: string | null
    }
)

export type LearningItemType =
  | "course"
  | "qualification"
  | "certification"
  | "activity"

export type UserPlannerItemStatus =
  | "locked"
  | "unlocked"
  | "in_progress"
  | "completed"
  | "incomplete"

type LearningContentType =
  | "course"
  | "assessment"
  | "assignment"
  | "article"
  | "class"
  | "video"
  | "discussion"
  | "scheduled_assessment"

export type VideoBookmark = {
  uuid: string
  comment: string
  timestamp: number
  user: string
  course: string
  language: string
  video: string
}

export enum AssessmentType {
  pre = 1,
}

export type VideoLanguage = {
  language: string
  localeCode?: string
  uuid: string
  url: string
}

export type LearningItem = {
  id: number
  learningContentId: number
  unlockDate: string | null
  status: UserPlannerItemStatus
  sequence: number
  milestone: {
    id: number
    title: string
  }
  accessStatus: AccessStatus
} & (
  | {
      uuid: string
      discussionType: "post_discussion" | "reply_discussion"
      instructions: string
      learningContentType: "discussion"
      defaultHashtag: string
    }
  | {
      uuid: string
      name: string
      isDefault: boolean
      duration: string
      learningContentType: "class"
    }
  | {
      classId: {
        id: number
        uuid: string
        name: string
      }
      professor: {
        name: string
        uuid: string
      }
      startTime: string
      endTime: string
      learningContentType: "class_schedule"
    }
  | {
      uuid: string
      title: string
      shortTitle: string
      learningContentType: "assignment"
    }
  | {
      uuid: string
      title: string
      url?: string
      // in seconds
      duration: number
      bookmarkVideo: VideoBookmark[]
      learningContentType: "video"
      languagesAvailable?: {
        url: VideoLanguage[]
        // subtitles: {
        //   language: string
        //   locale_code?: string
        //   uuid: string
        //   subtitle: string
        // }[]
      }
    }
  | {
      uuid: string
      title: string
      // in seconds
      optimumTime: number
      content?: string
      bookmarkArticle: {
        uuid: string
        comment: string
        user: string
        course: string
        article: string
      }[]
      learningContentType: "article"
    }
  | {
      startTime: string
      endTime: string
      duration: string
      assessment: {
        id: number
        title: string
        assessmentType: string
      }
      learningContentType: "assessment_schedule"
    }
  | {
      attempt?: {
        uuid: string
        status: AttemptStatus
        result?: AttemptResult
        score: number | null
      }
      instructions?: string
      uuid: string
      title: string
      assessmentType: number
      courseId: string | null
      totalNumberOfQuestions: number
      showAnswers?: boolean
      showScore?: boolean
      learningContentType: "assessment"
    }
  | {
      uuid: string
      image: string
      name: string
      learningContentType: "course"
    }
  | {
      uuid: string
      instructions: string
      title: string
      attempt: {
        id: number
        score: 0 | 1 | null
        status: "completed" | "in_progress"
      } | null
      activityType: ActivityTypeEnum
      activityCategory: ActivityCategoryEnum
      questionText: null
      contentType: "activity"
      learningContentType: "activity"
    }
)

export type CreatedArticleBookmark = {
  uuid: string
  article: {
    uuid: string
    title: string
  }
  course: {
    uuid: string
    name: string
  }
  comment: string
  courseUserItem: string
}

export type CreatedVideoBookmark = {
  comment: string
  course: { uuid: string }
  courseUserItem: string
  timestamp: number
  uuid: string
}

export type PlannerListItem = {
  id: number
  uuid: string
  name: string
  slug: string
  image: string
  itemType: string | null
  itemLevel: string | null
  duration: number | null
  learningItemType: LearningItemType
  learningItemId: number
  progress: PlannerOverview["progress"]
  lastAccessed: string | null
  accessStatus: AccessStatus
  status: UserPlannerItemStatus
  courseCode?: string | null
}

export enum AccessStatus {
  PARTIALLY_RESTRICTED = "partially_restricted",
  COMPLETELY_RESTRICTED = "completely_restricted",
  UNRESTRICTED = "unrestricted",
}

export type Enrollment = {
  batchAssignedAt: string
  shortTitle: string | null
  id: number
  type: "qualification" | "course" | "certification"
  name: string
  uuid: string
  slug: string
  enrollmentNumber?: string | null
  expiresAt?: string | null // Date Time ISO String
  idealTime?: string | null // Seconds
  parents: Enrollment[]
}

export enum ScheduleType {
  ASSESSMENT = "assessment_schedule",
  CLASS = "class_schedule",
  ASSIGNMENT = "assignment",
}

export type UpcomingPlannerItem = {
  id: number
  learningContentType: LearningContentType
  learningContentId: number
  scheduleId: number
  accessStatus: AccessStatus
} & (
  | {
      startTime: string
      endTime: string
      duration: string
      assessment: {
        id: number
        uuid: string
        title: string
      }
      scheduleType: ScheduleType.ASSESSMENT
    }
  | {
      classId: {
        id: number
        uuid: string
        name: string
      }
      professor: {
        fullName: string
        uuid: string
      }
      lectures: {
        id: number
        uuid: string
        name: string
        course: string
      }[]
      videoMeeting: VideoMeeting | null
      startTime: string
      endTime: string
      scheduleType: ScheduleType.CLASS
    }
)
export type Resource = {
  learningItemType: LearningItemType
  learningItemId: number
  learningItemName: string | null
  name: string
  accessStatus: AccessStatus
  url: string
  dateCreated: string
}

export type PlannerOverview = {
  progress: {
    totalUserPlannerItems: number
    completedUserPlannerItems: number
  } | null
  dueAssessments: number
  dueAssignments: number
}

export type IncompleteItemsCount = {
  assignments: number
  assessments: number
  classes: number
}

export type Article = {
  id: number
  title: string
  content: string
}

interface VideoMeeting {
  id: number
  meetingUrl: string
  title: string
  meetingId: string
  startTime: string
  endTime: string
  meetingStartTime: string | null
  meetingEndTime: string | null
  joiningUrl: string | null
  recordings: { recording: string; startTime: string; endTime: string }[]
}

interface BaseClassDetailType {
  videoMeeting: VideoMeeting | null
  professor: {
    fullName: string
    uuid: string
  }
  lectures:
    | {
        id: number
        uuid: string
        name: string
        course: string
      }[]
    | null
  startTime: string
  endTime: string
  id: number
  learningContentId: number
  learningContentType: "class_schedule" | "class"
  planner: {
    id: number
    learningItemType: string
    learningItemId: number
  }
  // Backend used status key for themselves
  // status: ClassStatus
  scheduleId: number | null
  scheduleType: "class_schedule" | "class"
  attendanceStatus: attendanceStatus | null
}

export enum attendanceStatus {
  YetToStart = "yet_to_start",
  Absent = "absent",
  Present = "present",
  Rescheduled = "rescheduled",
  RescheduledAndAttended = "rescheduled_and_attended",
  RescheduledButNotAttended = "rescheduled_but_not_attended",
}

export interface ClassSchedule extends BaseClassDetailType {
  scheduleType: "class_schedule"
  learningContentType: "class_schedule"
  classId: {
    id: number
    uuid: string
    name: string
    duration: string
  }
}
export interface ClassDetail extends BaseClassDetailType {
  scheduleType: "class"
  learningContentType: "class"
  name: string
}

export type ClassDetailsType = (ClassSchedule | ClassDetail) & {
  accessStatus: AccessStatus
}

export type PlannerAccess = {
  id: number
  learningItemType: LearningItemType
  progress: {
    total: number
    completed: number
  }
  learningItemId: number
  accessStatus: AccessStatus
}

export type MultipleChoiceActivity = {
  id: number
  status: UserPlannerItemStatus
  attemptItemResponses: {
    activityItem: number
    id: number
    response: number[] | null
    isCorrect: boolean
    responseFile: null
  }[]
  activity: {
    id: number
    title: string
    activityType: ActivityTypeEnum.MULTIPLE_CHOICE
    questionText: null
    activityItems: {
      id: number
      itemType: "text"
      description: ""
      text: string // separator = __blank__
      file: null
      sequence: number
      options: string[]
      itemDetails: null
    }[]
  }
}

export type TextMatchingActivity = {
  id: number
  status: UserPlannerItemStatus
  attemptItemResponses: {
    activityItem: number
    id: number
    response: number[] | null
    isCorrect: boolean
    responseFile: null
  }[]
  activity: {
    id: number
    title: string
    activityType: ActivityTypeEnum.TEXT_MATCHING
    questionText: string // separator = %%
    activityItems: {
      id: number
      itemType: "text"
      description: string
      text: string
      file: null
      sequence: number
      options: string[]
      itemDetails: null
    }[]
  }
}

export type JumbledWordsActivity = {
  id: number
  status: UserPlannerItemStatus
  attemptItemResponses: {
    activityItem: number
    id: number
    response: number[] | null
    isCorrect: boolean
    responseFile: null
  }[]
  activity: {
    id: number
    title: string
    activityType: ActivityTypeEnum.JUMBLED_WORDS
    questionText: string
    activityItems: {
      id: number
      itemType: "text"
      description: string
      text: string
      file: null
      sequence: number
      options: string[]
      itemDetails: {
        sentenceType?: string
      } | null
    }[]
  }
}

export type PronunciationActivity = {
  id: number
  status: UserPlannerItemStatus
  attemptItemResponses: {
    activityItem: number
    id: number
    response: number[] | null
    isCorrect: boolean
    responseFile: null
    responseDetails: {
      responseText: string
      wrongIndices: number[]
    } | null
  }[]
  activity: {
    id: number
    title: string
    activityType: ActivityTypeEnum.PRONUNCIATION
    questionText: null
    activityItems: {
      id: number
      itemType: "audio"
      description: string
      text: string
      file: string
      sequence: number
      options: []
      itemDetails: {
        sentenceType: "words" | "sentence"
        additionalDescription?: string | null
      }
    }[]
  }
}

export type Activity =
  | MultipleChoiceActivity
  | TextMatchingActivity
  | JumbledWordsActivity
  | PronunciationActivity

export enum ActivityTypeEnum {
  MULTIPLE_CHOICE = "multiple_choice",
  TEXT_MATCHING = "text_matching",
  JUMBLED_WORDS = "jumbled_words",
  PRONUNCIATION = "pronunciation",
}
export enum ActivityCategoryEnum {
  PRE_COURSE = "pre_course",
  IN_COURSE = "in_course",
  POST_COURSE = "post_course",
}

export type ActivityUserResponse<T = true> = {
  id: number
  label: string
  value: string
  markedAnswer: T extends true ? number[] | null : (number | null)[]
  validation: "correct" | "incorrect" | null
  options: string[]
}

export type ActivityUserResponsePronunciation = {
  id: number
  label: string
  value: string
  preRecordedFile: string | null
  responseFile: string | null
  validation: "correct" | "incorrect" | null
  wrongIndices: number[]
  options: string[]
  type: "sentence" | "words"
  additionalDescription?: string | null
}

export enum RestrictionType {
  EXPIRED = "expired",
  REVOKED = "revoked",
  PAUSED = "paused",
}

export type LearningRestrictions = {
  restrictionType: RestrictionType
  learningItem: {
    id: number
    image?: string | null
    uuid: string
    name: string
    slug: string
    itemType: "course" | "certification" | "qualification"
    itemLevel: string | null
    duration: number | null // in days
    expiryDuration?: string | null // in seconds
    shortTitle?: string | null
    // order: number | null
    // code: string | null // only for qualification and certification
  }
}
export type ScoreItemType =
  | "self_paced"
  | "live_assessment"
  | "assignment"
  | "attendance"

export type GradeBookScore = {
  id: number
  uuid: string
  name: string
  image: string
  slug: string
  itemType: LearningItemType
  itemLevel: string
  duration: number
  expiryDuration: string
  shortTitle: string
  code: string
  score: {
    id: number
    userEnrollmentId: number
    learningItemId: number
    learningItemType: LearningItemType
    creditsEarned: number | null
    gpa: number
    user: string
  } | null
  items: {
    id: number
    uuid: string
    name: string
    image: string
    slug: string
    itemType: LearningItemType
    itemLevel: string | null
    duration: number
    expiryDuration: string | null
    shortTitle: string | null
    defaultReattemptsAllowed?: number | null
    score: {
      id: number
      userItemScores: {
        id: number
        scoreType: ScoreItemType
        score: number
      }[]
      userEnrollmentId: number
      learningItemId: number
      learningItemType: LearningItemType
      creditsEarned: number | null
      gpa: number
      user: string
    } | null
    analytics: {
      id: number
      startTime: string | null
      endTime: string | null
      averageCompletionTime: string | null
    } | null
  }[]
}
