import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  CircularProgress,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import useTabs, { Tabs } from "components/TabBar/hooks/useTabs"
import { AIToolsContext } from "features/AItools/components/Generator/context"
import { getToolName } from "features/AItools/helper"
import { NavArrowDown } from "iconoir-react"
import { useContext } from "react"

import LessonPlanTabs, { TabNames } from "../../LessonPlanTabs"
import HandoutContent from "./HandoutContent"
import LessonPlanContent from "./LessonPlanContent"
import NarrationContent from "./NarrationContent"

const toolType = ToolType.lessonPlan

const tabs: Tabs = [
  {
    name: TabNames.lessonPlan,
    content: LessonPlanContent,
  },
  {
    name: TabNames.handout,
    content: HandoutContent,
  },
  {
    name: TabNames.narration,
    content: NarrationContent,
  },
]

const Content = ({
  sequence,
  responseIds,
  isOpen,
  onClick,
}: {
  sequence: number
  responseIds: number[]
  isOpen?: boolean
  onClick: (state: string) => void
}) => {
  const [activeTab, setActiveTab] = useTabs({ tabs, useSearchParams: false })
  const {
    currentResponseId,
    setCurrentResponseId,
    setResponseIds,
    refetchOverviewData,
  } = useContext(AIToolsContext)

  const content = useQuery({
    queryKey: queries.aiTools.retrieveHistory(
      toolType,
      currentResponseId && responseIds.includes(currentResponseId)
        ? currentResponseId
        : responseIds[0]
    ).queryKey,
    queryFn: async () =>
      api.aiTools.retrieveContent<typeof toolType>({
        urlParams: {
          id:
            currentResponseId && responseIds.includes(currentResponseId)
              ? currentResponseId
              : responseIds[0],
          type: toolType,
        },
      }),
    onError: err => {
      if (err instanceof APIError) {
        toast.error(err.message || "Something went wrong")
      }
    },
  })

  const { mutate: regenerateResponse, isLoading: regenIsLoading } = useMutation(
    {
      mutationFn: (data: string) => {
        onClick(``)
        return api.aiTools.regenerateResponse({
          urlParams: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: currentResponseId!,
            type: toolType,
          },
          data: {
            regenerateInstruction: data,
          },
        })
      },
      onSuccess: () => {
        refetchOverviewData()
      },
    }
  )
  return (
    <AccordionItem
      disabled={content.isFetching || regenIsLoading || content.isError}
      className="overflow-hidden rounded-2xl border-2 border-solid border-surface-100 bg-white pt-0"
      value={`item-${sequence}`}
    >
      <AccordionTrigger
        className="p-3"
        onClick={() => {
          if (isOpen) {
            onClick(``)
            setResponseIds(undefined)
          } else {
            onClick(`item-${sequence}`)
          }
          setCurrentResponseId(responseIds[0])
          setResponseIds(responseIds)
        }}
        icon={
          content.isFetching || regenIsLoading ? (
            <CircularProgress />
          ) : content.isError ? (
            <Button
              variant="text"
              className="text-critical-500"
              onClick={() => {
                content.refetch()
              }}
            >
              Retry
            </Button>
          ) : (
            <NavArrowDown color="black" />
          )
        }
      >
        <div className="flex items-center gap-1">
          <div>
            <Typography
              variant="title2"
              color="common.white.500"
              className="rounded-3xl bg-black px-3 py-0 text-white"
            >
              {sequence}
            </Typography>
          </div>
          <Typography variant="title2">{getToolName(toolType)}</Typography>
        </div>
      </AccordionTrigger>
      <AccordionContent className="p-0">
        <LessonPlanTabs
          tabs={tabs}
          activeTabName={activeTab.name}
          onClick={state => {
            setActiveTab(state)
          }}
        />
        <activeTab.content
          output={content.data?.output}
          refetchOverviewData={refetchOverviewData}
          isPositiveResponse={content.data?.isPositiveResponse}
          regenerateResponse={regenerateResponse}
        />
      </AccordionContent>
    </AccordionItem>
  )
}

export default Content
