import {
  Button,
  TextArea,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ListPromptHistory,
  RetrievePrompt,
  SubjectiveAssessmentFormType,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import ErrorMessage from "components/ErrorMessage"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { Edit } from "iconoir-react"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { mapErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../../context"
import SelectLessonPlanSheet from "../../../helpers/SelectLessonPlanSheet"
import AssessmentStructure, {
  AssessmentStructureRow,
} from "../../AssessmentStructure"

type Form = SubjectiveAssessmentFormType

const toolType = ToolType.subjectiveAssessment

const BasicForm = ({
  openLessonPlanSelector,
  onGeneration,
}: {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
  openLessonPlanSelector?: boolean
}) => {
  const { setPromptDataId } = useContext(AIToolsContext)
  const [structure, setStructure] = useState<AssessmentStructureRow[] | null>(
    null
  )
  const [showErrors, setShowErrors] = useState<{
    selectedLessonPlan?: string
    structure?: string
  } | null>(null)

  const [searchParams] = useSearchParams()
  const mode = searchParams.get("mode") // "new" | "template"
  const isTemplateMode = mode === "template"

  const [openSidebar, setOpenSidebar] = useState(false)

  const [selectedLessonPlan, setSelectedLessonPlan] =
    useState<ListPromptHistory | null>(null)

  const [lessonPlanResponse, setLessonPlanResponse] = useState<number>(-1)

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>()

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: any) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateSubjectiveAssessment({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })

      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          mapErrors(setError, err, [
            ["duration"],
            ["grade"],
            ["subject"],
            ["curriculum"],
            ["topic"],
            ["country"],
            ["assessmentObjectives"],
            ["structure"],
            ["selectedLessonPlan"],
            ["questionDetails", "structure"],
            ["lessonPlanResponse", "selectedLessonPlan"],
          ])
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (isTemplateMode) {
      let hasError = false

      if (!selectedLessonPlan) {
        setShowErrors(prev => ({
          ...prev,
          selectedLessonPlan: "Lesson Plan is required",
        }))
        hasError = true
      }

      if (!structure || structure.length === 0) {
        setShowErrors(prev => ({
          ...prev,
          structure: "Assessment structure is required",
        }))
        hasError = true
      }

      if (hasError) return
      setShowErrors(null)

      mutate({
        // lessonPlanResponse:
        //   selectedLessonPlan &&
        //   selectedLessonPlan.responseIds &&
        //   selectedLessonPlan.responseIds.length > 1
        //     ? lessonPlanResponse
        //     : selectedLessonPlan?.promptDataId,
        lessonPlanResponse,
        question_details: structure,
      })
      return
    }
    let hasError = false
    if (!structure || structure.length === 0) {
      setShowErrors(prev => ({
        ...prev,
        structure: "Assessment structure is required",
      }))
      hasError = true
    }

    if (hasError) return
    setShowErrors(null)

    mutate({
      ...data,
      question_details: structure,
    })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="space-y-2">
        {!isTemplateMode ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              <TextField
                required
                label="Class/Grade"
                placeholder="Ex: 6th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Country"
                placeholder="Ex: India"
                errors={errors.country?.message}
                helperText={
                  "We use this to tailor the lesson plan to your region"
                }
              />
            </div>
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              <TextField
                required
                label="Subject"
                placeholder="Ex: Social Science"
                errors={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Curriculum"
                placeholder="Ex: CBSE"
                errors={errors.curriculum?.message}
              />
            </div>

            <TextField
              required
              label="topic"
              placeholder="Ex: Ashoka, the emperor who gave up war."
              errors={errors.topic?.message}
              {...register("topic", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextArea
              required
              rows={6}
              label="Objective"
              placeholder={`Ex: Summarize the rule of Ashoka, the renowned emperor of India.\nHighlight the significant contributions made by Ashoka during his reign.\nAnalyze the reasons behind Ashoka's transformation from a ruthless warrior to a pacifist ruler.`}
              errors={errors.assessmentObjective?.message}
              {...register("assessmentObjective", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <div>
                <Typography variant="strong">Lesson Plan</Typography>
                {selectedLessonPlan && (
                  <Typography variant="body">
                    {selectedLessonPlan.title}
                  </Typography>
                )}
              </div>
              <Button
                variant="text"
                onClick={() => {
                  setOpenSidebar(true)
                }}
                startAdornment={selectedLessonPlan ? <Edit /> : undefined}
              >
                {selectedLessonPlan ? "Edit" : "Select Lesson Plan"}
              </Button>
            </div>
            {showErrors?.selectedLessonPlan && (
              <ErrorMessage title={showErrors?.selectedLessonPlan} />
            )}
            <SelectLessonPlanSheet
              open={openSidebar}
              onOpenChange={() => setOpenSidebar(false)}
              onSelect={(subjectiveAssessment, lessonPlanResponseId) => {
                setSelectedLessonPlan(subjectiveAssessment)
                setLessonPlanResponse(lessonPlanResponseId)
                setOpenSidebar(false)
              }}
            />
          </div>
        )}

        <AssessmentStructure
          setStructure={setStructure}
          structure={structure}
          openLessonPlanSelector={openLessonPlanSelector}
        />
        {showErrors?.structure && (
          <ErrorMessage title={showErrors?.structure} />
        )}

        <Button
          variant="filled"
          type="submit"
          loading={isLoading}
          className="w-full"
        >
          Generate {getToolName(toolType)}
        </Button>
      </form>
      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default BasicForm
