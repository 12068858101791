import { Button, Select, TextArea, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  AssignmentProForm,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName, proPointScaleOptions } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.assignmentPro

type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}

const ProForm = ({ onGeneration }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AssignmentProForm>()
  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: AssignmentProForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateAssignmentPro({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })

      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!data.book) {
      delete data.book
    }

    ensurePlusAccess(async () => {
      mutate(data)
    })
  })
  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            label="Class/Grade"
            placeholder="Ex: 5th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            label="Curriculum"
            placeholder="Ex: CBSE"
            errors={errors.curriculum?.message}
          />
          <TextField
            label="Subject"
            placeholder="Ex: Environmental Studies"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="rubricScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                errors={errors.rubricScale?.message}
                label="Rubric Point Scale"
                options={proPointScaleOptions}
                placeholder="Ex: 7"
                mountOnBody
                onBlur={onBlur}
                value={proPointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <TextField
          label="book"
          placeholder="Ex: Environmental Studies Looking Around"
          errors={errors.book?.message}
          {...register("book")}
        />
        <TextField
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
          label="Chapter/topic"
          placeholder="Ex: Environmental Impact Study"
          errors={errors.topic?.message}
        />
        <TextArea
          rows={4}
          label="Objective/Description"
          placeholder="Ex: Analyze and present the effects of human activities on the local environment, focusing on how these activities impact plants, animals, and natural resources, and suggest ways to reduce negative impacts."
          errors={errors.assignmentObjectives?.message}
          {...register("assignmentObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default ProForm
