import {
  CircularProgress,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

import FirstModule from "./FirstModule"
import Note from "./Note"
import Reason from "./Reason"
import Request from "./Request"

const ReattemptDialog = ({
  open,
  onClose,
  title,
  type,
  onSubmit,
  learningContentId,
  userPlannerItemId,
}: {
  open: boolean
  onClose: () => void
  title: string
  type: "assessment" | "assignment"
  onSubmit: () => void
  learningContentId?: string
  userPlannerItemId?: number
}) => {
  const [step, setStep] = useState<
    "first-module" | "request" | "reason" | "note" | undefined
  >()

  const reattemptData = useQuery({
    queryKey: queries.batch.retrieveReattemptData(userPlannerItemId).queryKey,
    queryFn: () =>
      api.batch.retrieveReattemptData({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userPlannerItemId: userPlannerItemId!,
        },
        params: {
          fields: [
            "parent_planner_user_reattempt_counter",
            "parent_planner",
            "user_reattempt_counter",
          ],
        },
      }),
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
    enabled: open && Boolean(userPlannerItemId),
  })

  const { data, isLoading } = useQuery({
    queryFn: () => api.batch.listBatches(),
    queryKey: queries.batch.listBatches().queryKey,
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
    enabled: open,
  })

  useEffect(() => {
    if (reattemptData.data && reattemptData.data.userReattemptCounter) {
      setStep(
        reattemptData.data.userReattemptCounter.reattemptsAllowed === -1
          ? "first-module"
          : "request"
      )
    }
  }, [reattemptData.data])

  const qualificationTitle =
    reattemptData.data?.parentPlanner?.learningItem.shortTitle ||
    reattemptData.data?.parentPlanner?.learningItem.name

  return (
    <Dialog modal open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-[390px]">
        <DialogHeader hideClose={step === "note"}>
          <DialogTitle
            onBack={
              step === "reason"
                ? () => {
                    if (
                      reattemptData.data &&
                      reattemptData.data.userReattemptCounter
                        .reattemptsAllowed === -1
                    ) {
                      setStep("first-module")
                    } else {
                      setStep("request")
                    }
                  }
                : undefined
            }
          >
            <Typography variant="strong">
              {step === "note"
                ? "Your request has been submitted"
                : "Request Reattempt"}
            </Typography>
          </DialogTitle>
        </DialogHeader>
        {(reattemptData.isLoading || isLoading || !step) && (
          <DialogBody className="py-2.5">
            <div className="flex w-full items-center justify-center">
              <CircularProgress />
            </div>
          </DialogBody>
        )}
        {data && reattemptData.data && (
          <>
            {step === "first-module" && (
              <FirstModule
                reattemptCount={
                  reattemptData.data.parentPlanner &&
                  reattemptData.data.userReattemptCounter.reattemptsAllowed ===
                    -1
                    ? reattemptData.data.parentPlanner.userReattemptCounter
                        .reattemptsAllowed
                    : reattemptData.data.userReattemptCounter.reattemptsAllowed
                }
                title={qualificationTitle}
                onClick={() => {
                  setStep("reason")
                }}
              />
            )}
            {step === "request" && reattemptData.data.userReattemptCounter && (
              <Request
                data={data}
                type={type}
                title={title}
                userReattemptCounter={reattemptData.data.userReattemptCounter}
                onClick={() => {
                  setStep("reason")
                }}
                qualificationTitle={qualificationTitle}
              />
            )}
            {step === "reason" && reattemptData.data.userReattemptCounter && (
              <Reason
                onSubmit={() => {
                  setStep("note")
                }}
                type={type}
                learningContentId={learningContentId}
                userPlannerItemId={userPlannerItemId}
                userReattemptCounter={reattemptData.data.userReattemptCounter}
              />
            )}
            {step === "note" && (
              <Note
                onClick={() => {
                  onSubmit()
                  onClose()
                }}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ReattemptDialog
