import { Button, Select, TextArea, TextField, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { QuizForm, RetrievePrompt, ToolType } from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const NUMBER_OF_QUESTION_CHOICES = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
]

const toolType = ToolType.quiz

type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}
const BasicForm = ({ onGeneration }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<QuizForm>()

  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: QuizForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateQuiz({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      onGeneration(res)

      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    mutate(data)
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex flex-col gap-2">
        <TextField
          {...register("grade", {
            required: { value: true, message: "Required" },
          })}
          required
          label="Grade"
          placeholder="Ex: UKG"
          errors={errors.grade?.message}
        />
        <TextField
          {...register("curriculum", {
            required: { value: true, message: "Required" },
          })}
          required
          label="Curriculum"
          placeholder="Ex: CBSE"
          errors={errors.curriculum?.message}
        />

        <Controller
          control={control}
          name="numberOfQuestions"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              required
              label="Number of Questions"
              errors={errors.numberOfQuestions?.message}
              options={NUMBER_OF_QUESTION_CHOICES}
              placeholder="Ex: 10"
              value={NUMBER_OF_QUESTION_CHOICES.find(c => c.value === value)}
              mountOnBody
              onBlur={onBlur}
              onChange={val => onChange(val?.value)}
            />
          )}
          rules={{ required: { value: true, message: "Required" } }}
        />
        <TextArea
          rows={4}
          required
          {...register("description", {
            required: { value: true, message: "Required" },
          })}
          label="Assessment Description"
          placeholder="Ex: Action Words"
          errors={errors.description?.message}
        />
        <TextArea
          rows={4}
          required
          {...register("objective", {
            required: { value: true, message: "Required" },
          })}
          label="Learning Objectives"
          placeholder="Ex: Students should be able to differentiate action words from other parts of speech and apply them appropriately."
          errors={errors.objective?.message}
        />

        <Button type="submit" loading={isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default BasicForm
